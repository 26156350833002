import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Banner, PreviewCompatibleImage } from '../components/common';
import { ServiceInfo, Slider, Text } from '../components/home';

export const HomePageTemplate = ({
  slide,
  heading,
  image,
  info,
  fullImage,
  main,
  invitation
}) => (
  <>
    <Slider slide={slide} />
    <section className="section">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <Text text={heading} to="/about">
                  Saznaj više
                </Text>
                <Banner image={image} />
                <ServiceInfo info={info} />
                <Banner image={fullImage} />
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="is-size-3">{main.heading}</h3>
                    <p style={{ marginBottom: '0.6666em' }}>
                      {main.description}
                    </p>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-vertical">
                    <div className="tile">
                      <div className="tile is-parent is-vertical">
                        <article className="tile is-child">
                          <PreviewCompatibleImage imageInfo={main.image1} />
                        </article>
                      </div>
                      <div className="tile is-parent">
                        <article className="tile is-child">
                          <PreviewCompatibleImage imageInfo={main.image2} />
                        </article>
                      </div>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                        <PreviewCompatibleImage imageInfo={main.image3} />
                      </article>
                    </div>
                  </div>
                </div>
                <Text endText text={invitation} to="/contact">
                  Kontaktirajte nas
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

HomePageTemplate.propTypes = {
  slide: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      orientation: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  main: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
  }).isRequired,
  invitation: PropTypes.string.isRequired
};

const HomePage = ({
  data: {
    markdownRemark: { frontmatter }
  }
}) => (
  <Layout>
    <HomePageTemplate
      slide={frontmatter.slide}
      heading={frontmatter.heading}
      image={frontmatter.image}
      info={frontmatter.info}
      fullImage={frontmatter.full_image}
      main={frontmatter.main}
      invitation={frontmatter.invitation}
    />
  </Layout>
);

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }).isRequired
  }).isRequired
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slide {
          image {
            img: childImageSharp {
              id
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          heading
          description
        }
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        info {
          image {
            alt
            img {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          orientation
          route
          title
          description
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        main {
          heading
          description
          image1 {
            alt
            img {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image2 {
            alt
            img {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image3 {
            alt
            img {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        invitation
      }
    }
  }
`;
